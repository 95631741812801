@import "@STYLES/colors.scss";

.preview-sidebar {
    position: relative;

    width: 100%;
    min-height: 100%;
    padding: 25px 25px 25px 0;

    background-color: color(app-white);

    .chapters-title {
        margin-bottom: 30px;

        color: color(marriott-primary);
        font-weight: 700;
        font-size: 16px;
    }

    .chapters-list {
        //

        .chapter-list-item {
            &:not(:last-child) {
                margin-bottom: 30px;
            }

            .sections-title {
                margin-bottom: 15px;
                margin-left: 20px;

                color: color(marriott-primary);
                font-weight: 700;
                font-size: 12px;
            }

            ul {
                list-style: none;
                padding: 0;
                color: var(--marriott-primary);
                font-size: 12px;
                font-weight: 700;
                margin: 0 15px 0 20px;

                li {
                    padding-left: 10px;
                    font-size: 12px;
                    margin: 15px 0 0 0;

                    ul {
                        padding-left: 20px;
                        margin: 0;
                    }
                }
            }

            .link {
                margin-bottom: 15px;
                display: flex;
                justify-content: flex-start;
                line-height: 150%;
                text-align: left;

                &:hover {
                    color: color(marriott-primary);
                    text-decoration: underline;
                }
            }

            .chapter {
                .link {
                    color: color(marriott-primary);
                    font-weight: 700;
                    font-size: 14px;
                    justify-content: flex-start;
                }
            }

            .introduction-text {
                .link {
                    padding-left: 20px;
                    justify-content: flex-start;
                    font-size: 12px;
                }
            }

            .sections-list {
                .link {
                    padding-left: 40px;
                    justify-content: flex-start;
                    font-size: 12px;
                }
            }
        }
    }
}
